import request, { get } from '@/utils/request'

//获取首页订单
export const getOrderListByTempleId = (templeId) => {
  return request.get('/order/getOrderListByTempleId', {
    params: {
      templeId
    }
  }).catch(r=>{
    console.log(r)
  })
}

export const getOrderById = (orderId) => {
  return request.get('/order/getOrderById', {
    params:{
      orderId: orderId
    }
  }).catch(err => {
      return err
    })
}


export const getOrderListByUserId = (userId) => {
  return request.get('/order/getOrderListByUserId', {
    params:{
      userId: userId
    }
  }).catch(err => {
    return err
  })
}
